import DownloadIcon from '@mui/icons-material/GetApp';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from 'next/link';
import React, { useState } from 'react';
import UAParser from 'ua-parser-js';
import { useIsLoggedInAsAdminUser } from '../utils/firebase/auth';
import { useStorageUrl } from '../utils/firebase/storage';

export default function DownloadCogs() {
  const isLoggedInAsAdminUser = useIsLoggedInAsAdminUser();

  // Try to guess the platform the user wants from the browser
  // Note that Apple Silicon Macs will be `MacIntel`. There doesn't seem to be a reliable way to detect Apple Silicon
  const detectedPlatform = navigator.platform?.replace(' X11', '') as SupportedPlatform;
  const [selectedPlatform, setSelectedPlatform] = useState<SupportedPlatform>(
    detectedPlatform === 'MacIntel' ? 'MacArm64' : detectedPlatform // Default to Apple Silicon
  );

  return (
    <Box textAlign="center" padding={2}>
      <Box marginBottom="1em">
        <Typography color="secondary">Download COGS to register a new installation</Typography>
        <Typography variant="caption">
          <Link href="https://cogs.zendesk.com/hc/en-gb/articles/15087190507153-Operator-Computer-Specification">
            Recommended computer specifications
          </Link>
        </Typography>
      </Box>
      <PlatformSelector selected={selectedPlatform} onChange={setSelectedPlatform} />
      <Box>
        {selectedPlatform === 'Win32' && (
          <CogsDownloadLink path="downloads/COGS-Windows-Setup.exe" description="COGS" />
        )}
        {selectedPlatform === 'MacIntel' && (
          <CogsDownloadLink
            path="downloads/COGS-macOS.dmg" // Update to "downloads/COGS-macOS-x64.dmg" once COGS public for Apple Silicon is released
            description="COGS"
          />
        )}
        {selectedPlatform === 'MacArm64' && (
          <CogsDownloadLink path="downloads/COGS-macOS-arm64.dmg" description="COGS" />
        )}
        {selectedPlatform === 'Linux x86_64' && (
          <CogsDownloadLink path="downloads/COGS-linux-x64.AppImage" description="COGS" />
        )}
        {selectedPlatform === 'Linux aarch64' && (
          <CogsDownloadLink path="downloads/COGS-linux-arm64.AppImage" description="COGS" />
        )}
      </Box>
      <Box>
        {selectedPlatform === 'Win32' && (
          <CogsDownloadLink
            path="downloads/COGS-beta-Windows-Setup.exe"
            description="COGS - Beta"
          />
        )}
        {selectedPlatform === 'MacIntel' && (
          <CogsDownloadLink path="downloads/COGS-beta-macOS-x64.dmg" description="COGS - Beta" />
        )}
        {selectedPlatform === 'MacArm64' && (
          <CogsDownloadLink path="downloads/COGS-beta-macOS-arm64.dmg" description="COGS - Beta" />
        )}
        {selectedPlatform === 'Linux x86_64' && (
          <CogsDownloadLink
            path="downloads/COGS-beta-linux-x64.AppImage"
            description="COGS - Beta"
          />
        )}
        {selectedPlatform === 'Linux aarch64' && (
          <CogsDownloadLink
            path="downloads/COGS-beta-linux-arm64.AppImage"
            description="COGS - Beta"
          />
        )}
      </Box>
      {isLoggedInAsAdminUser && (
        <Box>
          {selectedPlatform === 'Win32' && (
            <CogsDownloadLink
              path="downloads/COGS-internal-Windows-Setup.exe"
              description="COGS - Internal"
            />
          )}
          {selectedPlatform === 'MacIntel' && (
            <CogsDownloadLink
              path="downloads/COGS-internal-macOS-x64.dmg"
              description="COGS - Internal"
            />
          )}
          {selectedPlatform === 'MacArm64' && (
            <CogsDownloadLink
              path="downloads/COGS-internal-macOS-arm64.dmg"
              description="COGS - Internal"
            />
          )}
          {selectedPlatform === 'Linux x86_64' && (
            <CogsDownloadLink
              path="downloads/COGS-internal-linux-x64.AppImage"
              description="COGS - Internal"
            />
          )}
          {selectedPlatform === 'Linux aarch64' && (
            <CogsDownloadLink
              path="downloads/COGS-internal-linux-arm64.AppImage"
              description="COGS - Internal"
            />
          )}
        </Box>
      )}
      <Typography variant="caption">
        <Link href="https://docs.cogs.show/releases/">Looking for an older version?</Link>
      </Typography>
    </Box>
  );
}

type SupportedPlatform = 'Win32' | 'MacIntel' | 'MacArm64' | 'Linux x86_64' | 'Linux aarch64';

function CogsDownloadLink(props: { path: string; description: string }) {
  const { url, filename } = useStorageUrl(props.path);

  return (
    <Button
      href={url}
      disabled={!url}
      startIcon={<DownloadIcon />}
      variant="contained"
      color="primary"
      style={{ margin: 4 }}
    >
      <div>
        {props.description}
        <Typography
          variant="caption"
          display="block"
          style={{ opacity: 0.5, textTransform: 'none' }}
        >
          {filename}
        </Typography>
      </div>
    </Button>
  );
}

function PlatformSelector(props: {
  selected: SupportedPlatform;
  onChange: (platform: SupportedPlatform) => void;
}) {
  return (
    <ButtonGroup color="primary" style={{ marginBottom: 16 }}>
      <Button
        variant={props.selected === 'Win32' ? 'contained' : undefined}
        onClick={() => props.onChange('Win32')}
      >
        <Stack>
          Windows
          <PlatformSelectorDetail>64-bit</PlatformSelectorDetail>
        </Stack>
      </Button>
      <Button
        variant={props.selected === 'MacArm64' ? 'contained' : undefined}
        onClick={() => props.onChange('MacArm64')}
      >
        <Stack>
          macOS<PlatformSelectorDetail>Apple Silicon</PlatformSelectorDetail>
        </Stack>
      </Button>
      <Button
        variant={props.selected === 'MacIntel' ? 'contained' : undefined}
        onClick={() => props.onChange('MacIntel')}
      >
        <Stack>
          macOS
          <PlatformSelectorDetail>Intel</PlatformSelectorDetail>
        </Stack>
      </Button>
      <Button
        variant={props.selected === 'Linux x86_64' ? 'contained' : undefined}
        onClick={() => props.onChange('Linux x86_64')}
      >
        <Stack>
          Linux
          <PlatformSelectorDetail>x64</PlatformSelectorDetail>
        </Stack>
      </Button>
      <Button
        variant={props.selected === 'Linux aarch64' ? 'contained' : undefined}
        onClick={() => props.onChange('Linux aarch64')}
      >
        <Stack>
          Linux
          <PlatformSelectorDetail>ARM64</PlatformSelectorDetail>
        </Stack>
      </Button>
    </ButtonGroup>
  );
}

function PlatformSelectorDetail({ children }: { children: React.ReactNode }) {
  return (
    <Typography sx={{ textTransform: 'initial' }} variant="caption">
      {children}
    </Typography>
  );
}
