import ComputerIcon from '@mui/icons-material/DesktopWindows';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RemoteIcon from '@mui/icons-material/SettingsRemote';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {
  activeLicenseKeyInfo,
  FirewardGetOutput,
  Installation,
  WardTimestamp,
} from 'cogsFirestore';
import dayjs from 'dayjs';
import lodash from 'lodash';
import { useRouter } from 'next/router';
import React, { useCallback, useMemo } from 'react';
import DownloadCogs from '../../components/DownloadCogs';
import InstallationStatusLight from '../../components/InstallationStatusLight';
import LicenseKeyTierDisplayName from '../../components/LicenseKeyDisplayName';
import Loading from '../../components/Loading';
import NewToCogs from '../../components/NewToCogs';
import PaperContainer from '../../components/PaperContainer';
import Placeholder from '../../components/Placeholder';
import useDate from '../../hooks/useDate';
import { useUserData } from '../../providers/UserDataProvider';
import { useLoginRequired } from '../../utils/firebase/auth';
import { EMULATOR_HOST } from '../../utils/firebase/environment';
import {
  deleteInstallation,
  installationFirebaseConsoleUrl,
  setInstallationName,
  unassignLicenseKey,
  useNgrokTunnelUrl,
} from '../../utils/firebase/firestore';
import { InstallationStatusUpdate } from '../../utils/firebase/realtimeDb';
import { installationName, installationSupportsRemoteNgrokUi } from '../../utils/installations';
import { platformString } from '../../utils/os';
import { Path } from '../../utils/routes';
import TextFieldWithSaveOnEnter from '../../components/TextFieldWithSaveOnEnter';

export type ActiveLicenseKeyInfo = NonNullable<ReturnType<typeof activeLicenseKeyInfo>>;

export default function Installations() {
  useLoginRequired();

  const now = useDate();

  const { userId, licenseKeys, installations, subscriptionsById } = useUserData();
  const activeLicenseForInstallation = useMemo(
    () =>
      lodash(licenseKeys)
        .filter(([_, license]) => Boolean(license.installationId))
        .map(([_, license]) => [license.installationId!, license] as const)
        .groupBy(([installationId]) => installationId)
        .mapValues((licenses) =>
          licenses
            .map(([_, license]) =>
              activeLicenseKeyInfo(
                license,
                license.subscription
                  ? subscriptionsById?.[license.subscription.subscriptionId]
                  : undefined,
                now
              )
            )
            .find((keyInfo): keyInfo is ActiveLicenseKeyInfo => Boolean(keyInfo))
        )
        .value(),
    [now, licenseKeys, subscriptionsById]
  );

  return (
    <>
      <PaperContainer>
        {!installations && <Loading />}
        {installations?.length === 0 && (
          <Placeholder>
            <Typography variant="body1" color="textSecondary">
              No installations found
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Download COGS from below to get started
            </Typography>
          </Placeholder>
        )}
        <List style={{ padding: '2em' }}>
          {installations
            ?.sort(([, installationA], [, installationB]) =>
              (installationA.hostname ?? '').localeCompare(
                installationB.hostname ?? '',
                undefined,
                {
                  sensitivity: 'base', // case insensitive
                }
              )
            )
            .map(([id, installation]) => {
              const activeLicenseInfo = activeLicenseForInstallation[id];
              return (
                <InstallationItem key={id} {...{ userId, id, installation, activeLicenseInfo }} />
              );
            })}
        </List>
      </PaperContainer>
      <NewToCogs />
      <DownloadCogs />
    </>
  );
}

function InstallationItem({
  userId,
  id,
  installation,
  activeLicenseInfo,
}: {
  userId: string | undefined;
  id: string;
  installation: Installation<FirewardGetOutput>;
  activeLicenseInfo: ActiveLicenseKeyInfo | undefined;
}) {
  const { isLoggedInAsAdminUser, installationStatuses } = useUserData();
  const platformInfo = platformString(installation);
  const installationStatus = installationStatuses?.[id];
  const lastNgrokTunnelUrl = useNgrokTunnelUrl(userId, id);
  const ngrokTunnelUrl =
    (EMULATOR_HOST ||
      (installationStatus?.status && installationStatus.status !== 'project closed')) &&
    installationSupportsRemoteNgrokUi(installation)
      ? lastNgrokTunnelUrl
      : null;
  const router = useRouter();

  const updateName = useCallback(
    (name: string) => {
      if (userId) {
        setInstallationName(userId, id, name);
      }
    },
    [userId, id]
  );

  return (
    <ListItem key={id}>
      <ListItemIcon
        title={`${installation.hostname} - ${id} ${
          installationStatus ? `(${installationStatus.status ?? 'offline'})` : ''
        }`}
        onDoubleClick={() => window.navigator.clipboard.writeText(id)}
      >
        <Box>
          <Box position="relative">
            <ComputerIcon />
            {installationStatus && <InstallationStatusLight overlay {...{ installationStatus }} />}
          </Box>
        </Box>
      </ListItemIcon>
      <ListItemText
        primary={
          <TextFieldWithSaveOnEnter
            saveOnBlur
            size="small"
            variant="standard"
            value={installation.name ?? ''}
            onValueChange={updateName}
            placeholder={installation.hostname}
            sx={{
              width: '100%',
              '.MuiInput-underline::before': {
                borderColor: 'transparent',
              },
              '.MuiInput-input': {
                paddingBottom: '1px',
              },
              '.MuiInput-input:not(:focus)::placeholder': {
                color: 'currentColor',
                opacity: 1,
              },
            }}
          />
        }
        secondary={
          <Typography variant="body2" color="textSecondary">
            {platformInfo}
            <br />
            <LicenseKeyTierDisplayName tier={activeLicenseInfo?.tier ?? null} />{' '}
            {[
              versionString(installation),
              activeLicenseInfo && licenseEndString(activeLicenseInfo),
              installationStatus
                ? lastUpdatedString(installationStatus)
                : lastVerifiedString(installation),
            ]
              .filter(Boolean)
              .join(', ')}
          </Typography>
        }
      />
      <Tooltip title="Open COGS Remote" placement="left">
        <IconButton
          color="primary"
          href={
            Path.Installations +
            encodeURIComponent(id) +
            '/remote/?' +
            new URLSearchParams(router.query as Record<string, string>).toString()
          }
          target="_blank"
          disabled={!ngrokTunnelUrl}
        >
          {ngrokTunnelUrl && <RemoteIcon />}
        </IconButton>
      </Tooltip>
      <InstallationOptions {...{ userId, id, installation, isLoggedInAsAdminUser }} />
    </ListItem>
  );
}

function InstallationOptions({
  userId,
  id,
  installation,
  isLoggedInAsAdminUser,
}: {
  userId: string | undefined;
  id: string;
  installation: Installation<FirewardGetOutput>;
  isLoggedInAsAdminUser: boolean | undefined;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleDelete = useCallback(async () => {
    handleClose();
    if (!userId) {
      console.warn('No user ID');
      return;
    }
    if (installation.licenseKeyId) {
      await unassignLicenseKey(userId, installation.licenseKeyId, id);
    }
    await deleteInstallation(userId, id);
  }, [handleClose, userId, installation, id]);

  const handleOpenFirebaseConsole = useCallback(() => {
    if (userId) {
      window.open(installationFirebaseConsoleUrl(userId, id));
    }
  }, [userId, id]);

  return (
    <>
      <IconButton onClick={handleClick} size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
        {isLoggedInAsAdminUser && (
          <MenuItem onClick={handleOpenFirebaseConsole}>
            <OpenInNewIcon />
            &nbsp;Open in Firebase console
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

function versionString({ version, releaseType }: Installation) {
  const releaseTypeSuffix = releaseType && releaseType !== 'public' ? '-' + releaseType : '';
  return version ? 'v' + version + releaseTypeSuffix : undefined;
}

function lastUpdatedString(installation: InstallationStatusUpdate) {
  return `last updated ${dayjs(installation.updated).fromNow()}`;
}

function lastVerifiedString(installation: Installation) {
  if (installation.lastSeen) {
    const lastSeen = (installation.lastSeen.time as WardTimestamp).toMillis();
    return `last verified ${dayjs(lastSeen).fromNow()}`;
  }
}

export function licenseEndString(activeLicenseInfo: ActiveLicenseKeyInfo) {
  return `expires ${dayjs(activeLicenseInfo.end).fromNow()}`;
}
