import { LicenseTier } from 'cogsFirestore';
import { useUserData } from '../providers/UserDataProvider';
import React, { useMemo } from 'react';

export default function LicenseKeyTierDisplayName({
  tier,
  stripCogsPrefix,
}: {
  tier: LicenseTier | null;
  stripCogsPrefix?: boolean;
}) {
  const { allProducts } = useUserData();
  const productForTier = useMemo(
    () => allProducts?.find(([, product]) => product.metadata?.cogs_license === tier)?.[1],
    [allProducts, tier]
  );

  let name = tier ? productForTier?.name ?? tier : 'COGS Lite';
  if (stripCogsPrefix) {
    name = name.replace(/^COGS /i, '');
  }

  return <>{name}</>;
}
