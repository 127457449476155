import contentDisposition from 'content-disposition';
import { getDownloadURL, getMetadata, getStorage, ref } from 'firebase/storage';
import { basename } from 'path';
import { useEffect, useMemo, useState } from 'react';
import firebaseApp from './firebaseApp';

const storage = getStorage(firebaseApp);

// No emulator here because we always point to live data for downloads

export function useStorageUrl(path: string) {
  const [url, setUrl] = useState<string>();
  const [filename, setFilename] = useState<string>(basename(path));
  const pathRef = useMemo(() => ref(storage, path), [path]);

  useEffect(() => {
    setFilename(basename(path));
  }, [path]);

  useEffect(() => {
    (async () => {
      try {
        const contentDispositionHeader = (await getMetadata(pathRef))?.contentDisposition;
        if (contentDispositionHeader) {
          const filename = contentDisposition.parse(contentDispositionHeader).parameters.filename;
          if (filename) {
            setFilename(filename);
          }
        }
      } catch (e: any) {
        console.warn(`Could not get metadata: ${pathRef.fullPath}\n${e?.message ?? e}`);
      }
    })();
  }, [pathRef, setUrl]);

  useEffect(() => {
    (async () => {
      try {
        setUrl(await getDownloadURL(pathRef));
      } catch (e: any) {
        console.warn(`Could not get storage URL: ${pathRef.fullPath}\n${e?.message ?? e}`);
      }
    })();
  }, [pathRef, setUrl]);

  return { url, filename };
}
