import TextField, { TextFieldProps } from '@mui/material/TextField';
import usePrevious from '@react-hook/previous';
import { ReactNode, useCallback, useEffect, useState } from 'react';

export default function TextFieldWithSaveOnEnter(
  props: TextFieldProps & {
    value: string | undefined;
    onValueChange: (value: string) => void;
    saveOnBlur?: boolean;
    helperComponent?: (args: {
      unsaved: boolean;
      reset: () => void;
      save: () => void;
    }) => ReactNode;
  }
) {
  const { value, onValueChange, saveOnBlur, helperComponent, ...textFieldProps } = props;
  const previousValue = usePrevious(value);

  const [focused, setFocused] = useState(false);
  const [visibleValue, setVisibleValue] = useState(value ?? '');
  const unsaved = visibleValue !== (value ?? '');

  const reset = useCallback(() => {
    setVisibleValue(value ?? '');
  }, [value]);

  const save = useCallback(() => {
    onValueChange(visibleValue);
  }, [onValueChange, visibleValue]);

  // Update state when data loaded
  useEffect(() => {
    if (value && previousValue === undefined) {
      reset();
    }
  }, [value, previousValue, reset]);

  // Update when value prop updated
  useEffect(() => {
    if (value !== undefined && !focused) {
      reset();
    }
  }, [value, focused, reset]);

  // When not focused, update the displayed value
  useEffect(() => {
    if (!focused && value === (previousValue ?? '')) {
      reset();
    }
  }, [focused, value, previousValue, reset]);

  const handleKeyboardActions = useCallback(
    (e: React.KeyboardEvent<HTMLElement>) => {
      const input = e.currentTarget.querySelector('input[type=text]') as
        | HTMLInputElement
        | undefined;

      switch (e.key) {
        case 'Enter':
          if (!e.shiftKey) {
            e.preventDefault();
            unsaved && save();
            input?.blur();
          }
          break;
        case 'Escape':
          e.preventDefault();
          reset();
          setTimeout(() => input?.blur(), 0);
          break;
      }
    },
    [unsaved, save, reset]
  );

  return (
    <TextField
      {...textFieldProps}
      value={visibleValue}
      onChange={(e) => setVisibleValue(e.target.value)}
      onKeyDown={(e) => {
        handleKeyboardActions(e);
        props.onKeyDown?.(e);
      }}
      onFocus={(e) => {
        setFocused(true);
        props.onFocus?.(e);
      }}
      onBlur={(e) => {
        setFocused(false);
        if (saveOnBlur) {
          save();
        }
        props.onBlur?.(e);
      }}
      helperText={helperComponent ? helperComponent({ unsaved, reset, save }) : props.helperText}
    >
      {props.children}
    </TextField>
  );
}
