import { Installation } from 'cogsFirestore';
import macosRelease from 'macos-release';
import windowsRelease from 'windows-release';

export function platformString(installation: Installation | undefined) {
  if (installation?.os === undefined) return;
  switch (installation.os.platform) {
    case 'darwin': {
      const { name } = macosRelease(installation.os.release) ?? { name: installation.os.release };
      return `macOS ${name}`;
    }
    case 'win32':
      return `Windows ${windowsRelease(installation.os.release)}`;
    case 'linux':
      return `Linux ${installation.os.release}`;
    default:
      return installation.os.platform;
  }
}
