import SchoolIcon from '@mui/icons-material/School';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function NewToCogs() {
  return (
    <Box textAlign="center" padding={2}>
      <Typography color="secondary">New to COGS?</Typography>
      <Button variant="text" startIcon={<SchoolIcon />} href="https://cogs.show/tutorials">
        Tutorials
      </Button>
    </Box>
  );
}
