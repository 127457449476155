import { useEffect, useState } from 'react';

export default function useDate(updateInterval = 60_000) {
  const [now, updateNow] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => updateNow(Date.now()), updateInterval);
    return () => clearInterval(interval);
  }, [updateInterval]);

  return now;
}
