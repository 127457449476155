import FlagIcon from '@mui/icons-material/Flag';
import PeopleIcon from '@mui/icons-material/People';
import PlayIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import Box from '@mui/material/Box';
import { common, green, red } from '@mui/material/colors';
import { InstallationStatus, InstallationStatusUpdate } from '../utils/firebase/realtimeDb';

export default function InstallationStatusLight({
  installationStatus,
  overlay,
}: {
  installationStatus: InstallationStatusUpdate;
  overlay?: boolean;
}) {
  return (
    <Box
      sx={{
        ...(overlay && {
          position: 'absolute',
          top: -6,
          right: -6,
        }),
        width: 16,
        height: 16,
        borderRadius: '50%',
        backgroundColor: installationStatus.status ? green.A700 : red.A700,
        boxShadow: `0 0 0 1px ${common.white}`,
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <InstallationStatusIcon status={installationStatus.status} />
    </Box>
  );
}

export function InstallationStatusIcon({ status }: { status: InstallationStatus | undefined }) {
  const style: React.CSSProperties = {
    height: 12,
    width: 12,
    color: common.white,
    opacity: 0.9,
    position: 'absolute',
  };
  switch (status) {
    case 'setup':
      return <StopIcon {...{ style }} />;
    case 'pre-show':
      return <PeopleIcon {...{ style }} />;
    case 'in progress':
      return <PlayIcon {...{ style }} />;
    case 'finished':
      return <FlagIcon {...{ style }} />;
    default:
      return null;
  }
}
