import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import React from 'react';

export default function PaperContainer(props: Parameters<typeof Container>[0]) {
  return (
    <Container maxWidth="sm" style={{ marginTop: '2em', marginBottom: '2em' }}>
      <Paper
        {...{ ...props, style: { minHeight: '300px', position: 'relative', ...props.style } }}
      />
    </Container>
  );
}
