import { FirewardGetOutput, Installation } from 'cogsFirestore';
import { InstallationStatus } from 'cogsRealtimeDb';
import { gte as semverGte } from 'semver';

export function installationSupportsRemoteNgrokUi(installation: Installation<FirewardGetOutput>) {
  return Boolean(installation.version && semverGte(installation.version, '4.13.0'));
}

export function installationName(
  installation: { name?: string; hostname?: string },
  installationId: string
) {
  return installation.name || installation.hostname || `No name (${installationId})`;
}

export const ALL_INSTALLATION_STATUSES: InstallationStatus[] = [
  'project closed',
  'setup',
  'pre-show',
  'in progress',
  'finished',
];
